.services-card {
    transition: .6s ease-in-out;
    padding: 40px 40px 0;
    width: 100%;
    border: 1px solid #c1c1c1;
    text-align: start;
    position: relative;
    box-shadow: inset 0 0 0 0 #005498!important;
}
.services-card h1 {
    transition: .3s ease-in-out;
   
    color: #001219;
}
.services-card p {
    font-weight: 500;
    padding-bottom: 40px;
}
.services-card-icon {
    z-index: 2;
    position: relative;
    padding: 25px 0;
    transition: .6s ease-in-out;
}
.services-card-icon img {
    height: 70px;
}
.services-card:after {
    transition: .3s ease-in-out;
    content: "";
    position: absolute;
    height: 115px;
    width: 140px;
    -webkit-clip-path: polygon(25% 0,100% 0,75% 100%,0 100%);
    clip-path: polygon(25% 0,100% 0,75% 100%,0 100%);
    background-color: #e9ecef;
    z-index: -1;
    bottom: 0;
    left: 0;
}
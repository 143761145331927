#contact-section {
    width: 100%;
    background: #f8f9fa;
    padding: 1% 5%;
}
.contact-section-form {
    margin-top: 35px;
    width: 100%;
    box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.09);
    padding: 35px;
}
.contact-section-form h3 {
    font-weight: 600;
    font-size: 1.1rem;
    direction: ltr;
}
.contact-section-form-submit {
    transition: .4s ease-in-out;
    cursor: pointer;
    height: 60px;
    margin-top: 25px;
    width: 100%;
    background: #005498;
    border-radius: 7px;
    box-shadow: 0 8px 24px hsla(210,8%,62%,.2);
    text-align: center;
    color: #fff;
    font-weight: 700;
    border: 3px solid #005498;
}
.contact-section-form input {
    height: 50px;
    border: 2px solid #ced4da;
    padding: 0 15px;
}
.contact-section-form textarea{
    border: 2px solid #ced4da;
    padding: 0 15px;
}
.contact-section-form button h1{
    color: white;
    font-size: 1.3rem;
}
.swiper {
    width: 80%;
    margin: auto;
    height: 300px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    box-shadow: 0 8px 24px hsla(210,8%,62%,.1);
    transform: rotate(0) scale(1);
    border-radius: 11px;
  }